<template>
    <div  v-if="property_value" class="newsletterMini">
        <NuxtLink :to="property_value" class="group/newsletterContainer" target="_blank">
        <div class="flex flex-col justify-center items-center sm:mx-0 p-6 rounded-lg ">
            <h2 class="text-xl font-bold font-[Prata] text-textcolor group-hover/newsletterContainer:text-evellePrimaryHover transition">
                <webmessage wsm="wsm_newsletterHeadline"></webmessage>
            </h2>
            <p class="text-md group-hover/newsletterContainer:text-evellePrimaryHover transition">
                <webmessage wsm="wsm_newsletterHeadline2"></webmessage>
            </p>

            <!-- <div class="sm:w-full mt-4 flex gap-2 flex-wrap">
                <label for="email" class="mb-2 text-sm font-medium text-gray-900 sr-only">E-mail</label>

                <div class="relative grow min-w-[250px]">
                    <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                        <svg class="sm:w-8" xmlns="http://www.w3.org/2000/svg" version="1.0" width="25px" height="25px"
                            viewBox="0 0 1280.000000 1280.000000" preserveAspectRatio="xMidYMid meet">

                            <g transform="translate(0.000000,1280.000000) scale(0.100000,-0.100000)" fill="#e0d5a3"
                                stroke="none">
                                <path
                                    d="M680 9996 c8 -7 944 -706 2080 -1552 1136 -846 2411 -1796 2834 -2111 l769 -573 31 23 c17 13 1296 966 2841 2117 1546 1151 2814 2097 2819 2102 6 4 -2555 8 -5689 8 -4821 0 -5697 -2 -5685 -14z" />
                                <path
                                    d="M626 7018 c-4 -1366 -6 -2856 -4 -3311 l3 -828 1146 1308 c631 719 1421 1621 1757 2005 l610 696 -1721 1283 c-947 705 -1736 1293 -1753 1306 l-32 23 -6 -2482z" />
                                <path
                                    d="M12065 9482 c-11 -10 -789 -592 -1730 -1292 -940 -700 -1718 -1280 -1727 -1289 -16 -15 2 -39 290 -367 169 -193 955 -1090 1747 -1994 l1440 -1643 3 1652 c1 908 1 2394 0 3301 -3 1650 -3 1651 -23 1632z" />
                                <path
                                    d="M7335 5955 c-533 -398 -974 -721 -980 -717 -5 3 -441 327 -967 719 -527 392 -962 713 -967 713 -5 0 -762 -858 -1681 -1907 l-1672 -1908 2652 -3 c1458 -1 3841 -1 5295 0 l2643 3 -1669 1905 c-919 1048 -1673 1908 -1677 1912 -4 3 -443 -319 -977 -717z" />
                            </g>
                        </svg>
                    </div>
                    <input type="email" id="emailMini" v-model="email"
                        class="block w-full p-2 sm:pl-12 pl-12 text-md text-textcolor border border-gray-300 rounded-lg bg-gray-50 focus:ring-secondary focus:border-secondary outline-none focus:shadow-lg transition-all duration-200"
                        :placeholder="emailPlaceholder" required>
                </div>

                <button @click="signUpForNewsletter" type="submit" :disabled="email == ''"
                    class="text-white whitespace-nowrap grow break-keep right-2.5 bottom-2.5 sm:px-2 disabled:opacity-50 bg-secondary hover:bg-hover focus:outline-none font-medium rounded-lg text-sm font-[Prata] px-4 py-2">
                    <webmessage wsm="wsm_newsletterSignupButton"></webmessage>
                </button>
            </div> -->

        </div>
        </NuxtLink>
    </div>
</template>

<script setup lang="ts">
const api = useAPI();
const email = ref('')
const route = useRoute();
const data = await api.getPage(route.path);

const {pageProperties} = data;
const {$getPageProperty} = useNuxtApp();
const property_value = $getPageProperty("newsletterurl", pageProperties)?.property_value;
const signUpForNewsletter = async () => {
    await $fetch('/newsletter', {
        method: 'POST',
        body: email.value
    })
}
</script>
